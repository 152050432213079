import { BrowserEventType, BrowserTrackedEvent } from "@browser/event";
import { getCookieFromPage } from "@browser/plugins/ids/cookie";
import { getNeuronId } from "@browser/plugins/ids/neuronId";
import { getSiteId } from "@browser/plugins/ids/site";
import { TokenValue, getToken } from "@browser/token";
import { LocalStorageKey } from "@constants/keys";
import { CollectorPostBody } from "@core/api";
import { EventQueue } from "@core/queue";
import { constructApiUrl } from "@helper/helper";
import { log } from "@helper/log";

// General interface for BrowserQueueConfig.
export interface IBrowserQueueConfig {
  collectorUrl: string;
  sessionToken: Promise<TokenValue>;
  apiToken: string;
}

export type BrowserQueueConfig = IBrowserQueueConfig;

export class BrowserEventQueue extends EventQueue<BrowserTrackedEvent> {
  private config: BrowserQueueConfig;
  private buffer: BrowserTrackedEvent[];
  private flushIntervalId: number | undefined;

  constructor(config: BrowserQueueConfig) {
    super();
    this.config = config;
    this.buffer = this.load();
    if (this.buffer?.length > 0) {
      this.flush();
    }
  }

  // type guard to check if `meta` contains `identification` & `article` as meta type is a generic object

  private eventMetadataHasIdentificationField(
    meta: unknown,
  ): meta is { identification: { mysphw: string | null } } {
    return (
      typeof meta === "object" &&
      meta !== null &&
      "identification" in meta &&
      typeof (meta as any).identification === "object" &&
      (meta as any).identification !== null
    );
  }

  private metaHasArticleField(
    meta: unknown,
  ): meta is { article: { visitorCat?: string } } {
    return (
      typeof meta === "object" &&
      meta !== null &&
      "article" in meta &&
      typeof (meta as any).article === "object"
    );
  }

  private updateEventMetadataInBuffer() {
    const updatedMysphw = getCookieFromPage({
      dataLocation: "cookie",
      identifier: "mysphw",
    });

    const updatedVisitorCat = getCookieFromPage({
      dataLocation: "cookie",
      identifier: "visitorcat",
    });

    if (!updatedMysphw && !updatedVisitorCat) {
      return;
    }

    this.buffer = this.buffer.map((event) => {
      const updatedMeta = { ...event.meta };

      // Handle mysphw update
      if (
        updatedMysphw &&
        this.eventMetadataHasIdentificationField(updatedMeta)
      ) {
        updatedMeta.identification.mysphw =
          updatedMysphw || updatedMeta.identification.mysphw;
      }

      // Handle visitorCat update
      if (updatedVisitorCat && this.metaHasArticleField(updatedMeta)) {
        updatedMeta.article.visitorCat =
          updatedVisitorCat || updatedMeta.article.visitorCat;
      }

      return {
        ...event,
        meta: updatedMeta,
      };
    });
  }

  public async startFlush(flushingRateInMS: number) {
    if (this.flushIntervalId == undefined) {
      this.flushIntervalId = window.setInterval(() => {
        this.flush();
      }, flushingRateInMS);
    }
  }

  public async push(...ev: BrowserTrackedEvent<BrowserEventType>[]) {
    this.buffer.push(...ev);
    this.persist(this.buffer);

    if (this.buffer.length >= 15) {
      this.flush();
    }
  }

  public eventBuffer(): BrowserTrackedEvent<BrowserEventType>[] {
    return this.buffer;
  }

  public async sessionToken(): Promise<string> {
    return (await this.config.sessionToken).token;
  }

  public getSessionApiUrl() {
    return this.config.collectorUrl;
  }

  public getSessionApiToken() {
    return this.config.apiToken;
  }

  public async flush(useSendBeacon = false) {
    if (this.buffer.length === 0) {
      return;
    }
    /*
    By emptying the buffer, we can safeguard further on having multiple fetches for the same set of events.
    This further aids in the resolution of multiple identical events being fired over to Highway as the 
    frequency of firing is really fast at 500ms. There is a good chance that a clash of events could happen.
    */

    this.updateEventMetadataInBuffer();
    const events = [...this.buffer];
    this.clearQueue();

    const neuronId = getNeuronId();
    const payload: CollectorPostBody = {
      browserEvents: events,
      token:
        window.localStorage.getItem(LocalStorageKey.SessionToken) ??
        (await this.config.sessionToken)?.token,
      neuronId: neuronId,
      publication: getSiteId(),
    };

    const endpoint = constructApiUrl(
      this.config.collectorUrl,
      this.config.apiToken,
    );

    if (useSendBeacon) {
      if (navigator?.sendBeacon) {
        navigator.sendBeacon(endpoint, JSON.stringify(payload));
      } else {
        console.error("navigator.sendBeacon not supported");
      }
    } else {
      const rawResponse = await fetch(endpoint, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      try {
        const refreshedToken: TokenValue = await rawResponse.json();
        if (refreshedToken.token != null && refreshedToken.token !== "") {
          await this.updateToken(refreshedToken);
        } else {
          log.error(
            "A refreshed token could not be returned from the server. Refreshing token...",
          );
          /*
            In the event that we are unable to get a refreshed token from API, 
            we will attempt to refresh the token.
          */
          const updatedToken = await getToken(
            this.config.collectorUrl,
            this.config.apiToken,
            "script",
            neuronId,
            true,
          );
          await this.updateToken(updatedToken);
        }
      } catch (error) {
        log.error(`Flush did not succeed: ${error}`);
      }
    }
  }

  private async updateToken(updatedToken: TokenValue) {
    (await this.config.sessionToken).token = updatedToken.token;
    localStorage.setItem(LocalStorageKey.SessionToken, updatedToken.token);
  }

  private clearQueue() {
    /* 
    We are using .length = 0 instead of [] because using .length = 0 affects all references.
    This allows us to not have floating instances of the buffer around referencing wrongly.
    */
    this.buffer.length = 0;
    this.clearPersisted();
  }

  private persist(buffer: typeof this.buffer) {
    if (window.localStorage) {
      const serialized = JSON.stringify(buffer);
      window.localStorage.setItem(LocalStorageKey.Buffer, serialized);
    }
  }

  private clearPersisted() {
    window.localStorage.removeItem(LocalStorageKey.Buffer);
  }

  /*
    To prevent old events from the previous schema(s) coming in to pollute the dataset,
    We will instill a clearance of the old buffer key from local storage (i.e. _neuron.eventBuffer)
  */
  private clearOldBufferKey() {
    const oldBufferKeyExists = window.localStorage.getItem(
      LocalStorageKey.OldBuffer,
    );
    if (oldBufferKeyExists) {
      window.localStorage.removeItem(LocalStorageKey.OldBuffer);
    }
  }

  private load(): typeof this.buffer {
    if (window.localStorage) {
      this.clearOldBufferKey();
      const persisted = window.localStorage.getItem(LocalStorageKey.Buffer);
      if (persisted) {
        try {
          return JSON.parse(persisted);
        } catch (err) {
          console.error(err);
        }
      }
    }
    return [];
  }
}
